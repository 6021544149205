import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { concat, first, interval } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
@Injectable({
  providedIn: 'root'
})
export class PwaService {
  constructor(
    private swUpdate: SwUpdate,
    private appRef: ApplicationRef,
    private snackbar: MatSnackBar
  ) {
    const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
    const everyHour$ = interval(1 * 60 * 60 * 1000);
    const everyHourOnceAppIsStable$ = concat(appIsStable$, everyHour$);
    everyHourOnceAppIsStable$.subscribe(async () => {
      try {
        await swUpdate.checkForUpdate();
      } catch (err) {
        throw err;
      }

      swUpdate.unrecoverable.subscribe(event => {
        this.snackbar.open(
          'An error has occurred that requires a page reload. The page will be refreshed in 5 seconds.',
          null,
          {
            panelClass: 'error-toast'
          }
        );
        setTimeout(() => {
          document.location.reload();
        }, 5000);
      });
    });

    this.swUpdate.versionUpdates.subscribe(evt => {
      switch (evt.type) {
        case 'VERSION_READY':
          document.location.reload();
          break;
        case 'VERSION_INSTALLATION_FAILED':
          new Error(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
      }
    });
  }
}
